.dot{
    border: 2px solid red;
    border-radius: 100%;
    position: relative;
    width: 25px; height: 25px;
    display: none;
    z-index: 20;
  }
  .dot::before{
    content: "";
    display: block;
    position: absolute;
    width: 10px; height: 10px;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    background: red;
    border-radius: 100%;
  }