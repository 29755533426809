ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: white;
    height: 100%;
  }
  
  li {
    float: left;
  }
  
  li div {
    display: flex;
    text-align: center;
    padding: 0px 16px;
    text-decoration: none;
    align-items: center;
    line-height: 50px;
  }
  
  